import React, { useContext, useEffect, useRef, useState } from "react";
import { ContentContext } from "../../contexts/ContentContext";
import { Testimony } from "ContentTypes";
import TeastimonialCard from "./TeastimonialCard";
import Underline from "../misc/Underline";

const TestamonialGallery: React.FC = () => {
  const testimonies = useContext(ContentContext)?.testimonies;
  const inputRefs = useRef<HTMLButtonElement[]>([]);
  const boxRef = useRef<HTMLDivElement>(null);
  const [currIndex, setCurrIndex] = useState<number>(0);
  const isIndexSetRef = useRef<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(true)

  const addToInputRefs = (el: HTMLButtonElement) => {
    if (!inputRefs.current.includes(el) && el !== null) {
      inputRefs.current.push(el);
    }
  };

  const handleClickActive = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
    const dataset = e.currentTarget.dataset.focusbutton;
    inputRefs.current.forEach((btn: HTMLButtonElement)=> btn.classList.remove("active"))
    if (boxRef.current?.style && dataset && testimonies) {
      const sectionWidt = 100 / testimonies.length;
      const transleteBy = Number(dataset) * sectionWidt;
      boxRef.current.style.transform = `translateX(-${transleteBy}%)`;
      isIndexSetRef.current = true;
      setCurrIndex(Number(dataset));
      const timeout = setTimeout(
        () => {
          isIndexSetRef.current = false;
        },
        6000,
        () => clearTimeout(timeout)
      );
    }

  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRefs.current[currIndex - 1]) {
        inputRefs.current[currIndex - 1].classList.remove("active");
      }
      if (currIndex === 0 && testimonies?.length) {
        inputRefs.current[testimonies.length - 1].classList.remove("active");
      }
      inputRefs.current[currIndex].classList.add("active");
      if (
        !isIndexSetRef.current &&
        boxRef.current?.style &&
        testimonies?.length
      ) {
        const sectionWidt = 100 / testimonies.length;
        const transleteBy = currIndex * sectionWidt;
        boxRef.current.style.transform = `translateX(-${transleteBy}%)`;
        currIndex >= testimonies.length - 1
          ? setCurrIndex(0)
          : setCurrIndex(currIndex + 1);
      } else {
        return () => clearInterval(interval);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [currIndex]);

   
  return (
    <div className="testimony-gallery">
      <h3 className="content-header">Testimonials</h3>
      <Underline classname="medium" />
      <div
        className="inner-gallery-box"
        ref={boxRef}
        onMouseEnter={() => (isIndexSetRef.current = true)}
        onMouseLeave={() => (isIndexSetRef.current = false)}
      >
        {testimonies?.map((testimony: Testimony, index: number) => (
          <TeastimonialCard
            id={index}
            text={testimony.testimony}
            name={testimony.artistName}
            image={testimony.testimonyImage}
          />
        ))}
      </div>
      <div className="focus-btn-container">
        {testimonies?.map((testimony: Testimony, index: number) => {
          return (
            <button
              ref={addToInputRefs}
              key={index}
              type="button"
              className="focus-btn"
              data-focusbutton={index}
              onClick={(e) => {
                handleClickActive(e)
              }}
            ></button>
          );
        })}
      </div>
    </div>
  );
};

export default TestamonialGallery;
