import React from "react";
interface Prop {
  id: string;
  title: string;
  data: string;
  type: string;
  setData: React.Dispatch<React.SetStateAction<string>>;
}
const Input: React.FC<Prop> = ({ data,setData, id, title, type }) => {
  return (
    <div className="field mt-3">
      <input
        className="m-1"
        type={type}
        id={id}        
        required
        value={data}
        name={id}
        onChange={(e) =>{
          setData(e.target.value)
        }}  
        autoComplete="off"
      />
      <label htmlFor={id} title={title} data-title={id}></label>
    </div>
  );
};

export default Input;
