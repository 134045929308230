import React, { useEffect, useState } from "react";
import ContactCard from "../components/contact/ContactCard";
import TestamonialGallery from "../components/testimonial/TestamonialGallery";

import TestamonialGalleryMoblie from "../components/testimonial/TestimonialGalleryMobile";

const ContactContainer = () => {
  const [isMobile, setIsMobile] = useState<boolean>((
    () => window.innerWidth < 960 ?  true : false
  ));

  useEffect(() => {
    
    window.addEventListener("resize", () => {
      window.innerWidth < 960 ?  setIsMobile(true) : setIsMobile(false)  
    });
  }, []);
  return (
    <section className="my-container contact-container ">
      <ContactCard />
      {isMobile ? <TestamonialGalleryMoblie/> : <TestamonialGallery />}
    </section>
  );
};

export default ContactContainer;
