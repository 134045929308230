import React, { useEffect, useState } from "react";
import Button from "../components/misc/Button";

const HeroContainer: React.FC = () => {
  const setImage = () => {
    return window.innerWidth <= 600 ? "mobile" : "desktop";
  };
  const scrollBtnFunc = () => {
    window.scrollBy({ top: window.innerHeight, left: 0, behavior: "smooth" });
  };
  const [bgImage, setBgImage] = useState<string>(setImage());

  useEffect(() => {
    window.addEventListener("resize", () => {
      setBgImage(setImage());
    });
  }, []);
  return (
    <section className="hero-container">
      <video
         poster={`${process.env.PUBLIC_URL}/img/hero_vid_${bgImage}_Moment.jpg`}
        className="bg-video"       
        autoPlay
        loop
        playsInline
        muted
        src={`${process.env.PUBLIC_URL}/video/hero_vid_${bgImage}.mp4`}
      ></video>
      <img className = "header-logo" src={process.env.PUBLIC_URL + "/img/nype_logo.png"} alt="" />
      <Button
        type="button"
        text="See more"
        func={scrollBtnFunc}
        className="btn-wh-scroll"
      />
    </section>
  );
};

export default HeroContainer;
