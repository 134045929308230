import { Testimony } from "ContentTypes";
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowDown , faArrowUp} from "@fortawesome/free-solid-svg-icons";

const TestimonialCardMobile: React.FC<Testimony> = ({
  artistName,
  testimony,
  testimonyImage
}) => {
    const setTestimonyText = (text: string) =>{
        return text.length > maxLength
           ? text.slice(0, maxLength) + "..."
           : text;
       }
  const maxLength = 200;
  const [text, setText] = useState<string>(() => setTestimonyText(testimony));
  let isShowMore = useRef<boolean>(false)
  
  return (
    <div className="testimony-moblie-card">
      <div className="testimony-box hide-scrollbar">
          <img src={`${process.env.PUBLIC_URL}/${testimonyImage}`} alt="img" />
          <p className="testimony-text">"{text}"</p>      
          {testimony.length > maxLength ? (
            <button type="button" className="btn btn-more" onClick={() => {  
                isShowMore.current = !isShowMore.current
                isShowMore.current ? setText(testimony) : setText(setTestimonyText(testimony))      
            }}>
                <FontAwesomeIcon icon={isShowMore.current ? faArrowUp : faArrowDown }></FontAwesomeIcon>
            </button>
          ) : null}
        <div style={{ clear: "both" }}></div>
      </div>
      <p className="testimony-name">- {artistName}</p>
    </div>
  );
};

export default TestimonialCardMobile;
