import React from 'react'
interface Props{
    classname: string
}
const Underline: React.FC<Props> = ({classname = ""}) => {
  return (
    <div className={`underline-${classname}`}>
        <div className='underline-line'> </div>
        <div className='underline-line'> </div>
        <div className='underline-line'> </div>

    </div>
  )
}

export default Underline