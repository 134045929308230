import React, { useState } from "react";
import Underline from "../misc/Underline";
import Input from "./Input";
import Textarea from "./Textarea";

const ContactCard: React.FC = () => {
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errMessage, setErrMessage] = useState<string>("");


  return (
    <div className="contact-card">
      <h2 className="content-header">Let's work together</h2>
      <Underline classname="medium" />
      <p>If you'd like me to work with you , fill out my form!</p>
      <form name="contact" method="POST">
        <input type="hidden" name="form-name" value="contact"/>
        <Input
          setData={setFullName}
          data={fullName}
          id="name"
          title="Your name"
          type="text"
        />
        <Input setData={setEmail} data={email} id="email" title="Your email" type="email"/>
        <Textarea
          setData={setMessage}
          data={message}
          id="message"
          title="your message"
        />
        <button className="btn" type="submit" >Send</button>
        {errMessage ? (
          <div className="error-box mt-3 p-1">{errMessage}</div>
        ) : (
          ""
        )}
      </form>
    </div>
  );
};

export default ContactCard;
