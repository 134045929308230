import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useContext } from "react";
import { ContentContext } from "../../contexts/ContentContext";


const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="info-box my-container container">

          <a className="socials" href="https://www.instagram.com/nypemusic/"><FontAwesomeIcon icon={faInstagram}  /></a>
          <img className = "footer-logo" src={process.env.PUBLIC_URL + "/img/nype_logo.png"} alt="" />

      </div>

    </footer>
  );
};

export default Footer;
