import { createContext} from "react";
import { Hero, References, Contact, Testimony, Footer } from "ContentTypes";

export type contentContextType = {
  hero: Hero;
  reference: References;
  contact: Contact;
  testimonies: Testimony[];
  footer: Footer
  setContent: (data:contentContextType) => void
};

export const ContentContext = createContext<contentContextType | null>(null);
