import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
interface Prop {
  direction: string;
  slide: (direction: string) => void;
}
const NextButton: React.FC<Prop> = ({ direction, slide }) => {
  return (
    <>
      <button
        onClick={() => {
          slide(direction);
        }}
        type="button"
        className={`btn btn-next btn-${direction}`}
      >
        <FontAwesomeIcon icon={direction === "left" ? faArrowLeft : faArrowRight} />
      </button>
    </>
  );
};

export default NextButton;
