import { useRef, useEffect, useContext } from "react";
import AudioPlayerCard from "./AudioPlayerCard";
import NextButton from "../misc/NextButton";
import { ContentContext } from "../../contexts/ContentContext";
import {CurrentTrackProvider}   from "../../contexts/CurrentTrackContext"
import ReactPlayer from "react-player";

const AudioGallery = () => {
  const songs = useContext(ContentContext)?.reference.songs
  let transformWidth = useRef<number>(0);
  let cardWidth = useRef<number>(0);
  let cardsRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (cardsRef.current) {
      cardWidth.current = cardsRef.current.children[0]?.clientWidth;
    }
  }, []);
  const slide = (direction: string) => {
    if (cardsRef.current && cardsRef.current.parentElement) {
      const cards = cardsRef.current;
      const cardsParentRect =
        cardsRef.current.parentElement?.getBoundingClientRect();
      const cardsRect = cards.getBoundingClientRect();
      if (direction === "right" && cardsRect.right > cardsParentRect.right)
        transformWidth.current -= cardWidth.current;
      else if (direction === "left" && transformWidth.current < 0)
        transformWidth.current += cardWidth.current;
      cards.style.transform = `translate(${transformWidth.current}px)`;
    }
  };
  return (
    <div className="audio-gallery pl-5 pr-5" style={{width: "100%"}}>
      <NextButton slide={slide} direction={"left"} />
      <div className="clip-box">
        <div className="audio-gallery-content" ref={cardsRef}>

          {songs?.map((song) => (
          <ReactPlayer 
          url={song.songUrl}
          style={{margin: '0 1rem 0 0'}}
          height={"300px"}
        />   
            ))}
 
        </div>
      </div>
      <NextButton slide={slide} direction={"right"} />
    </div>
  );
};

export default AudioGallery;
