import React from "react";

interface Props {
  name: string;
  text: string;
  image:string;
  id: number
}
const TeastimonialCard: React.FC<Props> = ({ name, text,image,id }) => {
  return (
    <div className="testimony-card" id={id.toString()}>
      <div className="testimony-box">
        <div>
            <img src={`${process.env.PUBLIC_URL + '/'+ image}`} alt="img" />
          <p className="testimony-text">"{text}"
          </p>        
        </div>
        <div style={{clear:"both"}}></div>
        <p className="testimony-name">- {name} </p>
      </div>
    </div>
  );
};

export default TeastimonialCard;
