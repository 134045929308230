import React, {useContext} from 'react'
import { ContentContext } from '../../contexts/ContentContext'
import Underline from '../misc/Underline'

const About : React.FC = () => {
    const aboutText = useContext(ContentContext)?.hero.aboutText
  return (
    <div className='about mb-2 my-container'>
    <h1 className='content-header'>About NYPE</h1>
    <Underline classname = "medium"/>
    <p className='mt-4'>{aboutText}</p>
  </div>
  )
}

export default About