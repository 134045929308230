import React from 'react'
interface Prop{
    type : "submit" | "button" | "reset" | undefined,
    text: string
    className?: string 
    func: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
const Button :React.FC<Prop> = ({type, text, className = '', func}) => {
  return (
    <>
    <button type={type} onClick={(e) => func(e)} className={`btn ${className}`}>
        {text}
        </button></>
  )
}

export default Button