import { Testimony } from "ContentTypes"
import React, { useContext } from "react"
import { ContentContext } from "../../contexts/ContentContext"
import Underline from "../misc/Underline"
import TestimonialCardMobile from "./TestimonialCardMobile"

const TestamonialGalleryMoblie: React.FC = () => {
const testimonies = useContext(ContentContext)?.testimonies
    return(
        <div className="testimoal-gallery-moblie my-container">
            <h3 className="content-header">Testimonials</h3>
            <Underline classname="medium" />
            <div className="scrollbar" style={{width:'100%', overflow:'scroll'}}>
            <div className="testimoal-gallery-moblie-inner-box">
            {testimonies?.map(({artistName, testimony,testimonyImage} : Testimony) =>(
                <TestimonialCardMobile artistName={artistName} testimony={testimony} testimonyImage={testimonyImage}/>
            ))}
            </div>
            </div>
        </div>
    )
}

export default TestamonialGalleryMoblie
