import "./styles/css/main.css";
import { useState } from "react";
import HeroContainer from "./conatiners/HeroContainer";
import ReferencesContainer from "./conatiners/ReferencesContainer";
import content from "./content/engContent.json";
import ContactContainer from "./conatiners/ContactContainer";
import { ContentContext, contentContextType } from "./contexts/ContentContext";
import Footer from "./components/footer/Footer";
import About from "./components/about/About";

function App() {
  const [data, setData] = useState<contentContextType>({
    hero: content.Hero,
    reference: content.References,
    contact: content.Contact,
    testimonies: content.Testimonies,
    footer: content.Footer,
    setContent: () => setData,
  });

  return (
    <>
      <ContentContext.Provider value={data}>
        <HeroContainer />
        <main className="container">
          <ReferencesContainer />
          <About/>
          <ContactContainer />
        </main>
        <Footer />
      </ContentContext.Provider>
    </>
  );
}

export default App;
