import React, { useContext } from "react";
import Underline from "../components/misc/Underline";
import AudioGallery from "../components/references/AudioGallery";
import { ContentContext } from "../contexts/ContentContext";

const ReferencesContainer: React.FC = () => {
  const referencesData = useContext(ContentContext)?.reference
  return (
    <div className="references-container my-container">
        <h2 className="content-header">{referencesData?.header}</h2>
        <Underline classname="medium"/>
        <p>{referencesData?.description}</p>
        <AudioGallery/>
    </div>
  );
};

export default ReferencesContainer;
